<template>
  <div>
    <Parent />
    <ReferenceTable />
    <v-card class="mt-5">
      <CardHeader>
        <template slot="left">
          <div class="py-3 pl-4 d-flex align-center">
            <v-card
              color="#525252"
              flat
              height="29px"
              class="mr-2"
              width="172px"
            >
              <div class="d-flex justify-center align-center">
                <v-icon size="16">$saleWhite</v-icon>
                <div class="font-18px white--text pl-2">
                  {{ $t("page_goal_first_setting_sales_target") }}
                </div>
              </div>
            </v-card>
            <div class="ml-4 mr-2 font-18px">{{ year }} 年度</div>
            <div class="mr-2 font-14px">
              {{ termLabel }}
            </div>
          </div>
        </template>
      </CardHeader>
      <CardHeader class="borderless">
        <template slot="left">
          <div class="pt-5 pb-7 pl-10">
            <div class="font-13px pb-3">
              {{ $t("page_goal_annual_sales_target") }}
            </div>
            <div class="d-flex align-center">
              <div class="query mr-5">
                <Number
                  class="goal-input"
                  v-model.number="annual_goal"
                  @input="updateMonthlyValue()"
                  height="30"
                  flat
                  dense
                  full-width
                  hide-details
                  rounded
                />
              </div>
              <div class="mouse-pointer">
                <v-icon size="16px" @click="resetGoals">$refresh</v-icon>
              </div>
            </div>
          </div>
        </template>
      </CardHeader>
      <div v-for="(panel, i) in getPanelData" :key="i">
        <v-simple-table>
          <thead>
            <tr>
              <th class="width-150"></th>
              <th v-for="(item, j) in panel.months" :key="j">
                {{ item + "月" }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="width-150">目標</td>
              <td v-for="(item, j) in panel.months" :key="j">
                <Number
                  class="monthly-goal-input"
                  @input="updateTotalValue(monthlySales[panel.monthTxts[j]])"
                  v-model.number="monthlySales[panel.monthTxts[j]].value"
                />
              </td>
            </tr>
            <tr>
              <td class="width-150">実績</td>
              <td v-for="(item, j) in panel.months" :key="j">
                {{ monthlySales[panel.monthTxts[j]].actualIncome | toCurrency }}
              </td>
            </tr>
            <tr>
              <td class="width-150">達成率</td>
              <td v-for="(item, j) in panel.months" :key="j">
                <div
                  v-if="monthlySales[panel.monthTxts[j]].achievementRate > 0"
                  :class="
                    monthlySales[panel.monthTxts[j]].achievementRate > 100
                      ? 'green-text'
                      : 'red-text'
                  "
                >
                  {{ monthlySales[panel.monthTxts[j]].achievementRate }}%
                </div>
                <div v-else class="text-777">
                  {{ monthlySales[panel.monthTxts[j]].achievementRate }}%
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
      <v-card-actions class="py-10 ">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="submit()" width="84px">保存</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import CardHeader from "@/components/admin/ui/CardHeader";
import Parent from "@/views/admin/Store/Index";
import Number from "@/components/admin/ui/Input/Number";
import ReferenceTable from "@/components/admin/partials/GoalSetting/ReferenceTable";
import dayjs from "@/plugins/dayjs";
import goals from "@/views/admin/Goals/goals.mixin.js";
import { debounce } from "debounce";

export default {
  mixins: [goals],
  components: {
    CardHeader,
    Parent,
    Number,
    ReferenceTable
  },
  data() {
    return {
      loading: false,
      annual_goal: 0,
      termLabel: null,
      startTerm: null,
      endTerm: null,
      distributionFactors: [
        0.0813,
        0.065,
        0.0976,
        0.0976,
        0.0813,
        0.0813,
        0.0813,
        0.065,
        0.0813,
        0.0813,
        0.0813,
        0.105
      ],
      updateTemplate: null
    };
  },
  async created() {
    let month = dayjs().month() + 1;
    let year = dayjs().year();
    if (month < this.getShop.start_of_fiscal_year) {
      --year;
    }
    this.startTerm = dayjs(`${year}-${this.getShop.start_of_fiscal_year}`);
    this.endTerm = this.startTerm.add(11, "months");
    this.termLabel =
      this.startTerm.format("YYYY-MM") + "~" + this.endTerm.format("YYYY-MM");
    await this.setAllGoal(year);
  },
  methods: {
    async getDataFromApi(year) {
      this.loading = true;
      let params = {
        id: this.$route.params.shop_id,
        year
      };
      await this.$store
        .dispatch("GOAL_GET_ALL", params)
        .then(response => {
          this.getAllGoal = response.data.goal;
          this.getAllSales = response.data.sales;
          this.patchEditFormValues();
          if (response.data.second_half_goal) {
            this.updateTemplate = {
              first_half_goal: Object.values(response.data.first_half_goal),
              second_half_goal: Object.values(response.data.second_half_goal)
            };
          } else {
            this.updateTemplate = {
              first_half_goal: Object.values(response.data.first_half_goal)
            };
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async setAllGoal(year) {
      this.year = year;
      await this.getDataFromApi(year);
    },
    resetGoals: debounce(function() {
      this.annual_goal = 0;
      this.getPanelData.forEach(panel => {
        panel.months.forEach((ele, index) => {
          this.monthlySales[panel.monthTxts[index]].value = 0;
          this.monthlySales[panel.monthTxts[index]].achievementRate = 0;
          this.monthlySales[panel.monthTxts[index]].actualIncome = 0;
        });
      });
    }, 500),
    updateMonthlyValue: debounce(function() {
      this.getPanelData.forEach(panel => {
        panel.months.forEach((ele, index) => {
          let yearMonth = panel.yearMonths[index];
          let month = parseInt(yearMonth.split("/")[1]);
          let refValue = this.distributionFactors[month - 1];
          let calcValue = Math.round(this.annual_goal * refValue);
          this.monthlySales[panel.monthTxts[index]].value = calcValue;
          if (this.monthlySales[panel.monthTxts[index]].actualIncome > 0) {
            this.monthlySales[
              panel.monthTxts[index]
            ].achievementRate = Math.round(
              (calcValue /
                this.monthlySales[panel.monthTxts[index]].actualIncome) *
                100
            );
          }
        });
      });
    }, 500),
    updateTotalValue: debounce(function(index) {
      let goal = 0;
      if (parseInt(index.actualIncome) > 0) {
        index.achievementRate = Math.round(
          (index.value / parseInt(index.actualIncome)) * 100
        );
      }
      this.getPanelData.forEach(panel => {
        panel.months.forEach((ele, index) => {
          goal += this.monthlySales[panel.monthTxts[index]].value;
        });
      });

      this.annual_goal = goal;
    }, 500),
    submit() {
      for (let fields in this.updateTemplate) {
        let update = { months: {} };
        this.updateTemplate[fields].forEach(element => {
          if (typeof element === "string") {
            update.months[element] = this.monthlySales[element].value;
          } else {
            update.months["id"] = element;
          }
        });
        if (fields === "first_half_goal") {
          update.months.year = this.startTerm.year();
        } else {
          update.months.year = this.endTerm.year();
        }
        update.months.annual_goal = this.annual_goal;
        this.updateTemplate[fields] = update.months;
      }
      this.updateTemplate.shop_id = this.$route.params.shop_id;
      this.$store.dispatch("GOAL_UPDATE", this.updateTemplate).then(() => {
        this.getDataFromApi(this.year);
        this.updateTemplate = null;
      });
    }
  }
};
</script>
<style lang="scss" src="./styles.scss" scoped></style>

