import { mapGetters } from "vuex";

export default {
  data() {
    return {
      monthlySales: {
        jan_goal: {
          value: 0,
          placeholder: "¥0",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        },
        feb_goal: {
          value: 0,
          placeholder: "¥0",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        },
        mar_goal: {
          value: 0,
          placeholder: "¥0",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        },
        apr_goal: {
          value: 0,
          placeholder: "¥0",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        },
        may_goal: {
          value: 0,
          placeholder: "¥0",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        },
        jun_goal: {
          value: 0,
          placeholder: "¥0",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        },
        jul_goal: {
          value: 0,
          placeholder: "¥0",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        },
        aug_goal: {
          value: 0,
          placeholder: "¥0",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        },
        sep_goal: {
          value: 0,
          placeholder: "¥0",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        },
        oct_goal: {
          value: 0,
          placeholder: "¥0",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        },
        nov_goal: {
          value: 0,
          placeholder: "¥0",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        },
        dec_goal: {
          value: 0,
          placeholder: "¥ 0,000,000",
          actualIncome: 0,
          achievementRate: 0,
          disabled: true
        }
      },
      getAllGoal: null,
      getAllSales: null,
      year: null
    };
  },
  computed: {
    ...mapGetters(["getShop"]),
    getPanelData() {
      let startOfFiscalYear = this.getShop.start_of_fiscal_year;

      let panelData = [];
      var currentYear = this.year;
      let startFiscalMY = "";
      let lastFiscalMY = "";
      let monthArray = [];
      let monthTxtArray = [];
      let yearmonthArray = [];
      const monthNames = [
        "jan_goal",
        "feb_goal",
        "mar_goal",
        "apr_goal",
        "may_goal",
        "jun_goal",
        "jul_goal",
        "aug_goal",
        "sep_goal",
        "oct_goal",
        "nov_goal",
        "dec_goal"
      ];

      for (var j = 0; j < 12; j++) {
        var future = new Date(currentYear, parseInt(startOfFiscalYear) + j, 1);

        let fAllMonths = future.getMonth();
        let startFiscalM = ("0" + fAllMonths).slice(-2);
        const month_txt = monthNames[parseInt(fAllMonths) - 1];

        if (future.getMonth() == "0") {
          startFiscalM = 12;
          fAllMonths = 12;
        }
        let fAllYearMonth = future.getFullYear() + "/" + startFiscalM;

        if (j == 0) {
          if (startOfFiscalYear == 12) {
            future = new Date(currentYear, j, 1);
          }
          startFiscalMY = future.getFullYear() + "/" + startFiscalM;
        }
        if (j == 11) {
          if (startOfFiscalYear == 1) {
            future = new Date(currentYear, j, 1);
          }
          lastFiscalMY = future.getFullYear() + "/" + startFiscalM;
        }

        if (fAllMonths == 12) {
          future = new Date(currentYear, j, 1);
          fAllYearMonth = future.getFullYear() + "/" + startFiscalM;
          month_txt = monthNames[11];
        }
        monthArray.push(fAllMonths);
        monthTxtArray.push(month_txt);
        yearmonthArray.push(fAllYearMonth);
      }
      panelData.push({
        year: currentYear,
        startMY: startFiscalMY,
        lastMY: lastFiscalMY,
        months: monthArray,
        monthTxts: monthTxtArray,
        yearMonths: yearmonthArray
      });

      return panelData;
    }
  },
  methods: {
    patchEditFormValues() {
      for (let field in this.monthlySales) {
        this.monthlySales[field].value = this.getAllGoal[field]
          ? parseInt(this.getAllGoal[field])
          : 0;
        this.monthlySales[field].year = parseInt(
          this.getAllGoal[`${field}_year`]
        );
        // assign actual income for the month
        this.monthlySales[field].actualIncome = 0;
        const sales = this.getAllSales.find(
          sale =>
            sale.MONTH === field && sale.YEAR === this.monthlySales[field].year
        );
        if (sales !== undefined) {
          this.monthlySales[field].actualIncome = parseFloat(sales.sales);
        }

        this.monthlySales[field].achievementRate =
          parseInt(
            (this.monthlySales[field].actualIncome /
              this.monthlySales[field].value) *
              100
          ) || 0;
      }
      this.annual_goal = this.getAllGoal.annual_goal;
    }
  }
};
