var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._b({
    attrs: {
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "type": _vm.isFocused ? 'tel' : 'text',
      "min": "0",
      "inputmode": "numeric",
      "on": _vm.$listeners,
      "bind": _vm.$attrs
    },
    on: {
      "blur": function blur($event) {
        _vm.isFocused = false;

        _vm.$emit('input-lose-focus');
      },
      "keydown": [_vm.filterKey, function ($event) {
        if (!$event.type.indexOf('key') && $event.keyCode !== 48) { return null; }
        return _vm.handleZero($event);
      }, function ($event) {
        if (!$event.type.indexOf('key') && $event.keyCode !== 96) { return null; }
        return _vm.handleZero($event);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
        return _vm.handleDelete($event);
      }],
      "focus": function focus($event) {
        _vm.isFocused = true;
      },
      "keypress": function keypress($event) {
        return _vm.isNumberKey($event);
      }
    },
    model: {
      value: _vm._value,
      callback: function callback($$v) {
        _vm._value = $$v;
      },
      expression: "_value"
    }
  }, 'v-text-field', {
    disabled: _vm.disabled
  }, false));
}
var staticRenderFns = []

export { render, staticRenderFns }