<template>
  <div class="goal-reference-table">
    <v-card class="mt-2">
      <CardHeader class="borderless">
        <template slot="left">
          <div class=" pa-4">
            <div class="d-flex">
              <div class="mr-2 font-16px goal-ref-color">
                {{ $t("page_goal_first_reference") }}
              </div>

              <FiscalYearDropDown
                :removeFirst="true"
                @updatedSelectedYear="getDataFromApi"
              ></FiscalYearDropDown>
            </div>
            <div class="d-flex mt-4 align-center">
              <div class="font-weight-thin font-12px mr-5">
                {{ $t("page_goal_first_annual_sale_result") }}
              </div>
              <div class="font-weight-bold font-22px">
                {{ getActualAnnualGoal | toCurrency }}
              </div>
            </div>
          </div>
        </template>
      </CardHeader>
      <div v-for="(panel, i) in getPanelData" :key="i">
        <v-simple-table>
          <thead>
            <tr>
              <th class="width-150px"></th>
              <th v-for="(item, j) in panel.months" :key="j">
                {{ item + "月" }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="width-150px">目標</td>
              <td v-for="(item, j) in panel.months" :key="j">
                {{ monthlySales[panel.monthTxts[j]].value | toCurrency }}
              </td>
            </tr>
            <tr>
              <td class="width-150px">実績</td>
              <td v-for="(item, j) in panel.months" :key="j">
                {{ monthlySales[panel.monthTxts[j]].actualIncome | toCurrency }}
              </td>
            </tr>
            <tr>
              <td class="width-150px">達成率</td>
              <td v-for="(item, j) in panel.months" :key="j">
                <div
                  v-if="monthlySales[panel.monthTxts[j]].achievementRate > 0"
                  :class="
                    monthlySales[panel.monthTxts[j]].achievementRate >= 100
                      ? 'green-text'
                      : 'red-text'
                  "
                >
                  {{ monthlySales[panel.monthTxts[j]].achievementRate }}%
                </div>
                <div v-else class="text-777">
                  {{ monthlySales[panel.monthTxts[j]].achievementRate }}%
                </div>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card>
  </div>
</template>
<script>
import CardHeader from "@/components/admin/ui/CardHeader";
import FiscalYearDropDown from "@/components/admin/ui/FiscalYearDropDown";
import dayjs from "@/plugins/dayjs";
import goals from "@/views/admin/Goals/goals.mixin.js";

export default {
  mixins: [goals],
  components: {
    CardHeader,
    FiscalYearDropDown
  },
  data() {
    return {
      loading: false,
      dropdownStartYear: dayjs().year() - 1,
      annual_goal: 0
    };
  },
  computed: {
    getActualAnnualGoal() {
      let annualSum = 0;
      for (let field in this.monthlySales) {
        annualSum += this.monthlySales[field].actualIncome;
      }
      return annualSum;
    }
  },
  methods: {
    async getDataFromApi(year) {
      this.year = year;
      this.loading = true;
      let params = {
        id: this.$route.params.shop_id,
        year
      };
      await this.$store
        .dispatch("GOAL_GET_ALL", params)
        .then(response => {
          this.getAllGoal = response.data.goal;
          this.getAllSales = response.data.sales;
          this.patchEditFormValues();
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
